<template>
  <div class="decoration-internal-ref">
    <v-chip small class="v-chip--wrap">
      <v-icon left small color="purple">mdi-bookmark</v-icon>
      {{ title(item) }}
    </v-chip>
  </div>
</template>

<script>
import { friendlyShortTitle } from '@/data/toc';

export default {
  name: 'InternalRef',
  data: () => ({
    item: ''
  }),
  methods: {
    title: friendlyShortTitle
  }
};
</script>
