<template>
  <div class="akn-doc" data-name="appendix">
    <span class="akn-mainBody">
      <h2>DRAFT AMENDMENTS TO MARPOL ANNEX VI</h2>

      <section v-for="(entry, ix) in details" class="akn-part" :key="ix" :id="entry.toc.id">
        <h2 class="akn-heading">
          <span class="akn-heading--prefix">{{ entry.headingPrefix }}</span>
          <span class="akn-heading--suffix">{{ entry.headingSuffix }}</span>
        </h2>
        <section v-for="(amendment, a) in entry.amendments" :key="a" class="akn-paragraph" :id="`${entry.toc.id}__para__${amendment.num}`">
          <span class="akn-num">{{ amendment.num }}</span>
          <span class="akn-content">
            <span class="akn-p">
              {{ amendment.description }}:

              <div class="akn-quotedStructure" :id="amendment.key">
                <span class="akn-quotedStructure--startQuote">"</span>
                <div v-html="amendment.html_diff"></div>
              </div>
            </span>
          </span>
        </section>
      </section>
    </span>
  </div>
</template>

<script>
export default {
  name: 'ChangesetText',
  methods: {
    closed () {
      this.$emit('closed');
    }
  },
  data: () => ({
    changeset: null
  }),
  computed: {
    details () {
      // group by table of contents entry
      const expression = this.changeset.expression;
      const groups = new Map();
      const expressionToc = expression.expression.toc;

      for (const amendment of expression.appliedAmendments) {
        const details = {
          description: amendment.getDescription(expression),
          key: amendment.getKey(),
          // TODO: actual diff
          html_diff: amendment.htmlForAmendingText()
        };

        const id = amendment.elementId || amendment.afterElementId;
        const toc = expressionToc.closestMajorTocEntryForId(id);
        if (toc) {
          if (!groups.has(toc.id)) {
            groups.set(toc.id, []);
          }
          groups.get(toc.id).push(details);
        } else {
          console.log('no TOC entry for ' + id);
        }
      }

      const details = [];
      for (const [tocId, amendments] of groups) {
        const toc = expressionToc.itemsById.get(tocId);
        details.push({
          toc: toc,
          headingPrefix: toc.type === 'part' ? `Regulation ${toc.num}` : '',
          headingSuffix: toc.type === 'part' ? toc.heading : toc.title,
          amendments: amendments
        });
      }

      details.sort((a, b) => a.toc.sortKey - b.toc.sortKey);

      // give them numbers
      let n = 1;
      for (const d of details) {
        for (const a of d.amendments) {
          a.num = n;
          ++n;
        }
      }

      return details;
    }
  }
};
</script>
