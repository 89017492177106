<template>
  <div ref="main" :style="styles" :class="classes" class="decoration" @click.stop="activate"></div>
</template>

<script>
export default {
  name: 'GutterMarker',
  props: ['item', 'active', 'top'],
  mounted () {
    this.$refs.main.appendChild(this.item.$el);
  },
  methods: {
    activate () {
      this.$emit('activate', this.item);
    }
  },
  watch: {
    active () {
      this.item.ref.classList.toggle('decoration-active', this.active);
    }
  },
  computed: {
    styles () {
      return `top: ${this.top}px`;
    },
    classes () {
      return {
        'decoration-active': this.active
      };
    }
  }
};
</script>
