<template>
  <div class="pb-3 decoration-commencement">
    <v-chip>
      <v-icon small left color="blue">mdi-clock</v-icon>
      Inserted {{ info.provision_type }} in force from {{ info.date }}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'Commencement',
  data: () => ({
    info: {}
  })
};
</script>
