// some settings for the chrome of the app

export const chrome = {
  // show the navigation drawer toggle?
  showDrawerToggle: false,
  // should the navigation drawer be open?
  expandDrawer: true,
  // when showing diffs for amendments, diff previous version instead?
  diffPrevious: false,
  // which video guide to use
  guide: ''
};

export function matchWindowSize () {
  const screenSize = window.matchMedia('(max-width: 960px)');
  if (screenSize.matches) {
    chrome.expandDrawer = false;
  }
};
