<template>
  <div class="decoration-footnote">
    <div v-ripple class="rounded grey lighten-2">
      <div class="akoma-ntoso" ref="akn"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footnote',
  data: () => ({
    footnote: null
  }),
  mounted () {
    this.$refs.akn.appendChild(this.footnote);
  }
};
</script>
