<template>
  <v-container>
    <h1>IMO XML Document Project - Prototype and Demo</h1>

    <h3>What is this?</h3>
    <p class="text-body-1">This is a prototype project to demonstrate the possible value of XML documents for the IMO.</p>

    <h3>Demos</h3>
    <div class="row">
      <div class="col-md">
        <v-card class="mt-3 mb-3">
          <v-card-title>1. MARPOL Annex VI</v-card-title>
          <v-card-text>
            XML documents provide an opportunity to make long, legislative instruments easier to read, navigate and understand.
          </v-card-text>

          <v-card-actions>
            <v-btn to="/instrument" color="primary">Explore demo 1</v-btn>
            <v-spacer />
            <v-dialog v-model="helpDialog1" width="853">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="red" class="lighten-2" v-on="on" v-bind="attrs">
                  <v-icon>mdi-video</v-icon>
                  Video Guide
                </v-btn>
              </template>

              <v-card>
                <iframe v-if="helpDialog1" width="853" height="480" src="https://www.youtube.com/embed/1dEZRryb_3U"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                <v-card-actions>
                  <v-spacer />
                  <v-btn text @click="helpDialog1 = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </div>

      <div class="col-md">
        <v-card class="my-3">
          <v-card-title>2. Drafting amendments</v-card-title>
          <v-card-text>
            XML documents make it easier to draft and consider multiple amendments to a document.
          </v-card-text>

          <v-card-actions>
            <v-btn to="/changesets" color="primary">Explore demo 2</v-btn>
            <v-spacer />
            <v-dialog v-model="helpDialog2" width="853">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="red" class="lighten-2" v-on="on" v-bind="attrs">
                  <v-icon>mdi-video</v-icon>
                  Video Guide
                </v-btn>
              </template>

              <v-card>
                <iframe v-if="helpDialog2" width="853" height="480" src="https://www.youtube.com/embed/AmhosaHijM4"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                <v-card-actions>
                  <v-spacer />
                  <v-btn text @click="helpDialog2 = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </div>
    </div>

    <h3 class="mt-12 mb-3">Downloads</h3>

    <p>Download the XML files behind these prototypes.</p>

    <ul>
      <li v-for="(expr, ix) in expressions" :key="ix">
        <a :href="`/xml/${expr.filename}`">As at {{ expr.expression_date }}</a>
      </li>
    </ul>
  </v-container>
</template>

<script>
import { Marpol } from '@/data/marpol';

const expressions = [...Marpol.expressions];
expressions.reverse();

export default {
  name: 'Home',
  data: () => ({
    expressions: expressions,
    helpDialog1: false,
    helpDialog2: false
  })
};
</script>
