import { DateTime } from 'luxon';
import Vue from 'vue';
import ChangesetText from '@/components/ChangesetText';

const ChangesetTextGenerator = Vue.extend(ChangesetText);

class Changeset {
  constructor (id, date, parent, expression) {
    this.id = id;
    this.date = date;
    this.parent = parent;
    this.expression = expression;
  }

  generateAmendmentHtml () {
    // Use Vue to generate the AKN HTML for this changeset
    const generator = new ChangesetTextGenerator();
    generator.changeset = this;
    generator.$mount();
    return generator.$el.cloneNode(true);
  }
}

let _id = 0;
export const changesets = [];
export function newChangeset (original) {
  const today = DateTime.now();

  const chg = new Changeset(
    _id++,
    today.toISODate(),
    original,
    original ? original.expression.clone() : null
  );
  changesets.push(chg);
  return chg;
}

export function deleteChangeset (changeset) {
  changesets.splice(changesets.indexOf(changeset), 1);
}
