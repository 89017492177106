<template>
  <v-timeline-item fill-dot small>
    <v-card light color='blue lighten-5'>
      <v-card-title>{{convertDate(item.date)}}</v-card-title>
      <div
        v-for="(event, index) in item.events"
        :key="index"
        class="pb-3 px-4"
      >
        <span v-if="event.event === 'amendment'">Amended By <a class="text-decoration-none" :href="convertUrl(event.amending_uri)" target="_blank">Resolution MEPC.{{splitUrl(event.amending_uri)}}</a> </span>
        <span v-else-if="event.event === 'commencement'">Convention commences </span>
        <span v-else>{{capitalize(event.event)}}</span>
      </div>

      <v-card-actions v-if="item.expression_frbr_uri" class="pt-0 pl-4">
        <v-btn
          outlined
          color="primary"
          class="mt-0 mb-2"
          :to="'/instrument' + item.expression_frbr_uri"
        >Read this version</v-btn>

        <v-btn v-if="changes.length" outlined color="primary" class="mt-0 mb-2" @click="showChanges = !showChanges">What changed?</v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-if="showChanges" class="ma-3 pb-3">
          <v-card v-for="(change, index) in changes" :key="index" class="mb-3">
            <v-card-title class="text-subtitle-1 grey lighten-3">
              <router-link :to="'/instrument' + item.expression_frbr_uri + '#' + change.tocItem.id">
                {{ change.tocItem.title }}
              </router-link>
            </v-card-title>

            <div v-for="(diffset, ix) in change.changes" :key="ix" class="mb-3 pa-3">
              <v-divider v-if="ix > 0"></v-divider>
              <diff :diffset="diffset" :switchToSide="switchToSide"></diff>
            </div>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-switch v-model="switchToSide"  label="Show changes side-by-side"></v-switch>
            </v-card-actions>
          </v-card>
        </div>
      </v-expand-transition>
    </v-card>
  </v-timeline-item>
</template>

<script>
import { Marpol } from '../data/marpol';
import Diff from './Diff.vue';

export default {
  components: { Diff },
  name: 'HistoryItem',
  props: ['item'],
  data () {
    return {
      showChanges: false,
      changes: [],
      switchToSide: false
    };
  },
  mounted () {
    this.changes = this.determineChanges();
  },
  methods: {
    determineChanges () {
      // calculate changes
      const diffsets = Marpol.diffsets.filter(d => d.expression_frbr_uri === this.item.expression_frbr_uri);

      // each change is linked to an entry in the toc
      const changes = new Map();

      for (const diffset of diffsets) {
        const expr = Marpol.expressions.find(e => e.expression_frbr_uri === diffset.expression_frbr_uri);
        // what TOC entry does this fall under?
        const tocItem = expr.toc.closestTocEntryForId(diffset.element_id);

        if (!changes.has(tocItem.id)) {
          changes.set(tocItem.id, {
            tocItem,
            changes: []
          });
        }
        changes.get(tocItem.id).changes.push(diffset);
      }

      return [...changes.values()];
    },
    convertDate (date) {
      const newDate = new Date(date);
      const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];
      const newHistory = `${newDate.getDate()} ${monthNames[newDate.getMonth()]} ${newDate.getFullYear()}`;
      return newHistory;
    },
    capitalize (str) {
      if (typeof str === 'string') {
        return str.replace(/^\w/, c => c.toUpperCase());
      } else {
        return '';
      }
    },
    splitUrl (url) {
      let splitString = url.split('/').pop();
      splitString = splitString.split('-');
      const MEPCResolution = splitString[1] + `(${splitString[0]})`;
      return MEPCResolution;
    },
    convertUrl (url) {
      const linkedUrl = 'https://wwwcdn.imo.org/localresources/en/OurWork/Environment/Documents';
      const MEPCResolution = this.splitUrl(url);
      const completeUrl = linkedUrl + `/${MEPCResolution}.pdf`;
      return completeUrl;
    }
  }
};
</script>
