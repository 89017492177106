<template>
  <div>
    <div class="d-flex">
      <v-text-field
        hide-details
        single-line
        label="Search"
        clearable
        clear-icon="mdi-close-circle-outline"
        class="pt-0"
        v-model="needle"
        @keydown.enter="jumpToNext"
      ></v-text-field>

      <div v-if="searching" class="text-caption ma-2">{{ currentIndex + 1 }}/{{ marks.length }}</div>

      <v-icon @click="jumpToPrevious" active-class="">mdi-chevron-up</v-icon>
      <v-icon @click="jumpToNext">mdi-chevron-down</v-icon>

      <v-divider light class="mx-3" vertical></v-divider>

      <v-icon @click="close">mdi-close</v-icon>
    </div>

    <div class="pt-4">
      <v-checkbox hide-details v-model="keepTogether" label="All words together" class="mt-0" />

      <v-checkbox hide-details v-model="scope" label="General text" xclass="mt-0" value="text" />
      <v-checkbox hide-details v-model="scope" label="Tables" class="mt-0" value="tables" />
      <v-checkbox hide-details v-model="scope" label="Headings" class="mt-0" value="headings" />
      <v-checkbox hide-details v-model="scope" label="Paragraphs" class="mt-0" value="paragraphs" />
      <v-checkbox hide-details v-model="scope" label="Sub-paragraphs" class="mt-0" value="subparagraphs" />
      <v-checkbox hide-details v-model="scope" label="Annexes" class="mt-0" value="attachments" />
    </div>
  </div>
</template>

<script>
import Mark from 'mark.js';
import debounce from 'lodash/debounce';

export default {
  name: 'Search',
  props: ['expression'],
  data: () => ({
    needle: '',
    scope: ['text', 'tables', 'headings', 'attachments', 'paragraphs', 'subparagraphs'],
    keepTogether: true,
    marks: [],
    currentIndex: 0
  }),
  mounted () {
    this.document = document.querySelector('.primary-document');
  },
  computed: {
    searching () {
      return this.needle && this.needle.length > 0;
    }
  },
  methods: {
    jumpTo () {
      if (this.marks.length > 0) {
        const currentMark = this.marks[this.currentIndex];
        this.marks.forEach(mark => mark.classList.remove('current'));
        if (currentMark) {
          currentMark.classList.add('current');
          const position = currentMark.getBoundingClientRect().top + window.scrollY;
          window.scrollTo(0, position - 230);
        }
      }
    },
    jumpToPrevious () {
      if (this.marks.length > 0) {
        this.currentIndex += -1;
        if (this.currentIndex < 0) {
          this.currentIndex = this.marks.length - 1;
        }
        if (this.currentIndex > this.marks.length - 1) {
          this.currentIndex = 0;
        }
        this.jumpTo();
      }
    },
    jumpToNext () {
      if (this.marks.length > 0) {
        this.currentIndex += 1;
        if (this.currentIndex < 0) {
          this.currentIndex = this.marks.length - 1;
        }
        if (this.currentIndex > this.marks.length - 1) {
          this.currentIndex = 0;
        }
        this.jumpTo();
      }
    },
    search () {
      this.currentIndex = 0;

      if (this.needle && this.needle !== '') {
        if (!this.mark) {
          this.mark = new Mark(this.document);
        }

        // unmark existing marks
        this.mark.unmark({
          done: () => this.markResults()
        });
      } else {
        if (this.mark) {
          this.mark.unmark();
          this.mark = null;
        }
      }
    },
    markResults () {
      // this will mark search matches with <mark>...</mark>
      this.mark.mark(this.needle, {
        separateWordSearch: !this.keepTogether,
        filter: (text) => {
          if (text.parentElement.closest('table')) {
            return this.scope.includes('tables');
          }

          if (text.parentElement.closest('h1, h2, h3, h4, h5')) {
            return this.scope.includes('headings');
          }

          if (text.parentElement.closest('.akn-subparagraph')) {
            return this.scope.includes('subparagraphs');
          }

          if (text.parentElement.closest('.akn-paragraph')) {
            return this.scope.includes('paragraphs');
          }

          if (text.parentElement.closest('.akn-attachment')) {
            return this.scope.includes('attachments');
          }

          // match general text?
          return this.scope.includes('text');
        },
        done: () => {
          // stash the marks for later navigation
          this.marks = this.document.querySelectorAll('mark');
          this.jumpTo();
        }
      });
    },
    close () {
      this.needle = '';
      this.$emit('closed');
    }
  },
  watch: {
    expression () {
      // new expression, reset search
      this.needle = '';
    },
    scope () {
      this.search();
    },
    keepTogether () {
      this.search();
    },
    needle: debounce(function () {
      this.search();
    }, 250)
  }
};
</script>
