<template>
  <v-dialog
    max-width="900"
    v-model="dialog"
  >
    <v-card>
      <v-card-title class="grey lighten-2" v-if="diffsets.length === 1">Differences between {{ currExpression.expression_date }} and {{ prevExpression.expression_date }}</v-card-title>
      <v-card-title class="grey lighten-2" v-else>
        <div class="mr-3">Differences between</div>
        <v-select :items="options" v-model="diffset" hide-details></v-select>
      </v-card-title>

      <diff v-if="diffset" :diffset="diffset" :switchToSide="switchToSide"></diff>

      <v-divider></v-divider>

      <v-card-actions>
        <v-switch v-model="switchToSide" label="Show changes side-by-side"></v-switch>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Diff from '../components/Diff.vue';
import '../components/diffs.scss';
import { Marpol } from '../data/marpol';

export default {
  components: { Diff },
  name: 'Diffset',
  props: ['diffsets'],
  data: () => ({
    dialog: false,
    switchToSide: false,
    diffset: null
  }),
  beforeMount () {
    this.diffset = this.diffsets[this.diffsets.length - 1];
  },
  computed: {
    options () {
      return this.diffsets.map(d => {
        return {
          text: d.expression_frbr_uri.substring(d.expression_frbr_uri.lastIndexOf('@') + 1) + ' and ' +
                d.prev_expression_frbr_uri.substring(d.prev_expression_frbr_uri.lastIndexOf('@') + 1),
          value: d
        };
      }).reverse();
    },
    prevExpression () {
      return Marpol.expressions.find(e => e.expression_frbr_uri === this.diffset.prev_expression_frbr_uri);
    },
    currExpression () {
      return Marpol.expressions.find(e => e.expression_frbr_uri === this.diffset.expression_frbr_uri);
    }
  },
  methods: {
    show () {
      this.dialog = true;
    }
  }
};
</script>
