<template>
  <aside class="gutter" @click="clearPrimary">
    <div ref="gutterItems" class="gutter-items">
      <GutterMarker
        v-for="(m, ix) in markers"
        :key="ix"
        :item="m.item"
        :active="m.active"
        :top="m.top"
        @activate="activated"></GutterMarker>
    </div>
  </aside>
</template>

<script>
import { DecorationLayout } from '@/decorations/decorations';
import GutterMarker from './GutterMarker';

export default {
  name: 'Gutter',
  props: ['expression', 'items'],
  components: { GutterMarker },
  data: () => ({
    markers: []
  }),
  watch: {
    items () {
      this.markers = this.items.map((item) => {
        return {
          active: false,
          top: 1,
          item: item,
          // these are used by the gutter layout algorithm
          get ref () { return item.ref; },
          content: item.$el
        };
      });
      this.layout.decorations = [...this.markers];
      this.$nextTick(() => { this.layout.layout(); });
    }
  },
  methods: {
    activated (item) {
      const marker = this.markers.find((m) => m.item === item);
      for (const m of this.markers) {
        m.active = m === marker;
      }
      this.layout.primary = marker;
    },
    reset (contentRoot) {
      this.$refs.gutterItems.innerText = '';
      this.layout = new DecorationLayout(contentRoot);
      this.layout.init();
    },
    clearPrimary () {
      for (const m of this.markers) {
        m.active = false;
      }
      this.layout.primary = null;
    }
  }
};
</script>
