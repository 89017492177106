<template>
  <div class="decoration-infobox">
    <v-card
      color="grey lighten-2"
      elevation="0"
    >
      <v-card-subtitle class="pt-2 px-2 pb-0 font-weight-bold">
        <v-icon left small :color="info.colour">{{ info.icon ? info.icon : 'mdi-information' }}</v-icon>
        {{ info.title }}
      </v-card-subtitle>

      <v-card-text v-if="info.content" class="px-2 py-0 text-small">
        {{ info.content }}
      </v-card-text>

      <v-card-actions v-if="info.link">
        <v-btn small outlined color="primary" :href="info.link" target="_blank">
          {{ info.link_text ? info.link_text : 'Read more' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'InfoBox',
  data: () => ({
    info: {}
  })
};
</script>
