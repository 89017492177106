<template>
  <div class="primary-document-container">
    <article class="akoma-ntoso primary-document">
      <div class="limit-document-width" ref="container"></div>
    </article>
  </div>
</template>

<script>
import { chrome } from '@/data/chrome';

export default {
  name: 'ChangesetContent',
  props: ['changeset', 'expression'],
  data: () => ({
    chrome
  }),
  mounted () {
    this.insertAkn();
  },
  methods: {
    insertAkn () {
      this.$refs.container.innerHTML = '';
      this.$refs.container.appendChild(this.expression.element.cloneNode(true));
      this.displayDiffs();
    },
    displayDiffs () {
      for (const amendment of this.expression.appliedAmendments) {
        this.displayDiff(amendment);
      }
    },
    displayDiff (amendment) {
      const element = amendment.getAmendedElement(this.$refs.container);
      if (element) {
        // remove the updated element and put our diff in place instead
        const diff = document.createElement('div');
        diff.className = 'diffset changeset';

        const prev = this.changeset.parent ? this.changeset.parent.expression.appliedAmendments.find(a => a.id === amendment.id) : null;

        if (this.chrome.diffPrevious && prev) {
          // diff against the previous changeset
          amendment.diffAmendment(prev).then((html) => {
            diff.innerHTML = html;
          });
        } else {
          this.expression.diffAmendment(amendment).then((html) => {
            diff.innerHTML = html;
          });
        }

        element.insertAdjacentElement('afterend', diff);
        element.remove();
      }
    },
    scrollToAmendment (amendment) {
      const ref = amendment.getAmendedElement(this.$refs.container);
      if (ref) {
        this.$vuetify.goTo(ref, {
          offset: 150
        });
      }
    }
  },
  watch: {
    'expression.element': function () {
      this.insertAkn();
    },
    'chrome.diffPrevious': function () {
      this.insertAkn();
    }
  }
};
</script>
