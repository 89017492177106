<template>
  <v-dialog v-model="dialog" width="853" v-if="src">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="red" class="lighten-2 mt-3 mr-3" v-on="on" v-bind="attrs">
        <v-icon>mdi-video</v-icon>
        Video Guide
      </v-btn>
    </template>

    <v-card>
      <iframe v-if="dialog" width="853" height="480" :src="src"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { chrome } from '@/data/chrome';

export default {
  name: 'VideoGuide',
  data () {
    return {
      chrome,
      dialog: false
    };
  },
  computed: {
    src () {
      return {
        reading: 'https://www.youtube.com/embed/1dEZRryb_3U',
        amending: 'https://www.youtube.com/embed/AmhosaHijM4'
      }[this.chrome.guide];
    }
  }
};
</script>
