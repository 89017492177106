<template>
  <div class="decoration-incoming-refs">
    <v-card
      color="grey lighten-2"
      elevation="0"
    >
      <v-card-text class="pt-2 pl-2 pr-2 pb-0">
        References to here
      </v-card-text>

      <div class="pa-2">
        <v-chip
          v-for="ref in references"
          :key="ref.id"
          outlined
          small
          class="mb-2 mr-2 v-chip--wrap"
          @click.stop="scrollTo(ref.id)"
        >
          <v-icon left small color="purple">mdi-bookmark</v-icon>
          {{ title(ref) }}
        </v-chip>
      </div>
    </v-card>
  </div>
</template>

<script>
import { scrollToId } from '@/utils';
import { friendlyShortTitle } from '@/data/toc';

export default {
  name: 'IncomingRefs',
  data: () => ({
    references: []
  }),
  methods: {
    scrollTo (id) {
      scrollToId(id);
    },
    title: friendlyShortTitle
  }
};
</script>
