const initialUser = {
  premium: false,
  preferences: {
    fontSize: 15,
    sansSerifFont: false
  },
  settings: {
    footnotesAside: true,
    commencements: false,
    incomingRefs: false,
    internalRefs: false,
    infoboxes: false
  }
};

function loadUser () {
  let user = localStorage.getItem('user');
  if (user) {
    user = JSON.parse(user);
  } else {
    user = initialUser;
  }
  return user;
}

export function saveUser () {
  localStorage.setItem('user', JSON.stringify(user));
}

export const user = loadUser();

export function downgradeUser () {
  user.premium = false;
  user.preferences = { ...initialUser.preferences };
  user.settings = { ...initialUser.settings };
}

export function upgradeUser () {
  user.premium = true;
  user.settings = {
    footnotesAside: true,
    commencements: true,
    incomingRefs: true,
    internalRefs: true,
    infoboxes: true
  };
}
