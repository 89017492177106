<template>
  <div class="pb-3 decoration-external-form text-body-1">
    <v-card class="mx-auto" color="orange lighten-5" elevation="0">
      <div class="d-flex">
        <div class="flex-grow-1">
          <v-card-title>
            <v-icon left color="orange">mdi-clipboard-edit</v-icon>
            Form
          </v-card-title>
          <v-card-subtitle>Download this form to complete it offline.</v-card-subtitle>
        </div>
        <div class="pa-3">
          <v-btn color="orange" @click="msg">Download form</v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'ExternalForm',
  data: () => ({
    info: {}
  }),
  methods: {
    msg () {
      alert('In a real application, a PDF would now download.');
    }
  }
};
</script>
