<template>
  <div class="decorations-toolbar-wrapper" :style="{top: computedTop + 'px'}">
    <v-toolbar dense elevation="1">
      <v-menu offset-y :close-on-content-click="false" :close-on-click="false" ref="searchMenu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" class="font-weight-regular">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
        <v-card class="pa-3">
          <Search :expression="expression" @closed="closeSearchDialog"></Search>
        </v-card>
      </v-menu>

      <v-menu offset-y :close-on-content-click="false" :close-on-click="false" ref="applicabilityMenu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" class="font-weight-regular">
            <v-icon>mdi-ferry</v-icon>
          </v-btn>
        </template>
        <v-card class="pa-3">
          <Applicability :expression="expression" @closed="closeApplicabilityDialog" ref="applicabilityDialog"></Applicability>
        </v-card>
      </v-menu>

      <v-menu offset-y :close-on-content-click="false" :close-on-click="false" z-index="15" v-model="featureDialog">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" class="font-weight-regular">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-card class="pa-3">
          <div class="d-flex">
            <div>Which features must be visible?</div>
            <v-divider light class="mx-3" vertical></v-divider>
            <v-icon @click="featureDialog = false">mdi-close</v-icon>
          </div>
          <v-form>
            <v-checkbox :disabled="!user.premium" hide-details="true" v-model="user.settings.footnotesAside" label="Footnotes in margin" />
            <v-checkbox :disabled="!user.premium" hide-details="true" v-model="user.settings.incomingRefs" label="Incoming references" />
            <v-checkbox :disabled="!user.premium" hide-details="true" v-model="user.settings.internalRefs" label="Internal references" />
            <v-checkbox :disabled="!user.premium" hide-details="true" v-model="user.settings.commencements" label="In force dates" />
            <v-checkbox :disabled="!user.premium" hide-details="true" v-model="user.settings.infoboxes" label="Additional information" />
            <div class="text-caption ml-8">
              Loaded from this
              <a
                href="https://docs.google.com/spreadsheets/d/1OLygdgMWMaIOp4KCRToTrheo94EzJUSL8B4urYN5WSs/edit#gid=0"
                target="_blank">spreadsheet</a>.
            </div>
          </v-form>
        </v-card>
      </v-menu>
    </v-toolbar>
  </div>
</template>

<script>
import { user } from '../data/user';
import Search from './Search.vue';
import Applicability from './Applicability.vue';

export default {
  name: 'Toolbar',
  props: ['expression'],
  components: { Search, Applicability },
  data: () => ({
    user,
    featureDialog: false
  }),
  computed: {
    computedTop () {
      return this.$vuetify.application.bar + this.$vuetify.application.top;
    }
  },
  methods: {
    closeSearchDialog () {
      this.$refs.searchMenu.isActive = false;
    },
    closeApplicabilityDialog () {
      this.$refs.applicabilityDialog.reset();
      this.$refs.applicabilityMenu.isActive = false;
    }
  }
};
</script>

<style scoped>
  .decorations-toolbar-wrapper {
    position: fixed;
    right: 0px;
    z-index: 10;
  }
</style>
