export class TableOfContents {
  constructor (items) {
    this.items = items;
    this.itemsById = new Map();
    this.indexItems(items, null);
  }

  /** Mapping from toc element id to toc entry
   */
  indexItems (items, parent) {
    for (const item of items) {
      // add parent so we can walk upwards
      item.parent = parent;
      item.sortKey = this.itemsById.size;
      this.itemsById.set(item.id, item);

      if (item.children) {
        this.indexItems(item.children, item);
      }
    }
  }

  /**
   * Find the closest ancestor of an element that has an entry in the table of contents.
   *
   * @return [element, entry]
   */
  closestTocEntry (element) {
    while (element) {
      if (element.getAttribute('id') && this.itemsById.get(element.getAttribute('id'))) {
        return [element, this.itemsById.get(element.getAttribute('id'))];
      }
      element = element.parentElement;
    }
  }

  closestTocEntryForId (elementId) {
    while (elementId.length) {
      if (this.itemsById.has(elementId)) {
        return this.itemsById.get(elementId);
      }
      // chop of the last component
      elementId = elementId.slice(0, elementId.lastIndexOf('__'));
    }
  }

  closestMajorTocEntryForId (elementId) {
    let toc = this.closestTocEntryForId(elementId);
    const major = { part: 1, chapter: 1, attachment: 1 };

    while (toc && !major[toc.type]) {
      toc = toc.parent;
    }

    return toc;
  }
}

export function friendlyShortTitle (item) {
  if (item.type === 'paragraph') {
    const parent = item.parent.type === 'division' ? item.parent.parent : item.parent;
    const prefix = parent.type === 'part' ? 'Reg. ' + parent.num : parent.title;
    return prefix + ', P. ' + item.num;
  }
  return item.title;
}
