<template>
  <div>
    <v-sheet class="pa-4">
      <v-btn small text to="/changesets" class="mb-3">
        <v-icon>mdi-chevron-left</v-icon>
        Back
      </v-btn>

      <v-form class="mb-3">
        <v-text-field class="mb-3" label="Date" hide-details="auto" v-model="changeset.date" type="date" />
        <v-text-field label="Document ID" hide-details="auto" v-model="changeset.doc_id" />
      </v-form>

      <v-radio-group v-model="chrome.diffPrevious" v-if="changeset.parent">
        <v-radio label="Changes to principal text" :value="false" />
        <v-radio label="Changes since previous draft" :value="true" />
      </v-radio-group>

      <v-dialog width="1024" v-model="amendmentTextDialog">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" block :disabled="changeset.expression.appliedAmendments.length === 0">Amending text</v-btn>
        </template>

        <DraftAmendingText :changeset="changeset" @closed="amendmentTextDialog = false" />
      </v-dialog>
    </v-sheet>

    <div class="pa-3">
      <v-dialog
        width="700"
        v-model="amendmentsDialog"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" color="primary" block>
            Add draft amendment
          </v-btn>
        </template>

        <v-card class="grey lighten-3">
          <v-card-title>Add amendment</v-card-title>

          <v-card-text class="mt-5">
            <v-alert type="info">
              In a real system, amendments could be drafted on the fly or pre-prepared as below.
            </v-alert>
          </v-card-text>

          <v-expansion-panels class="px-3 pb-5">
            <v-expansion-panel v-for="amendment in available" :key="amendment.id">
              <v-expansion-panel-header>{{ amendment.title }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-btn @click="addAmendment(amendment)">Add draft amendment</v-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn text color="primary" @click="amendmentsDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-expansion-panels focusable class="px-3 pt-3" v-model="expanded">
      <v-expansion-panel v-for="(amendment, ix) in sortedAmendments" :key="ix">
        <v-expansion-panel-header>{{ amendment.title }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <AmendmentPanel :amendment="amendment" :changeset="changeset" @removed="removeAmendment" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { amendments } from '@/data/amendments';
import DraftAmendingText from './DraftAmendingText';
import AmendmentPanel from './AmendmentPanel';
import { chrome } from '@/data/chrome';

export default {
  name: 'ChangesetSidebar',
  components: { DraftAmendingText, AmendmentPanel },
  props: ['changeset'],
  data: () => ({
    amendmentsDialog: false,
    amendmentTextDialog: false,
    expanded: [],
    amendments: amendments,
    chrome
  }),
  computed: {
    available () {
      return amendments.filter((a) => !this.changeset.expression.appliedAmendments.some(x => x.id === a.id));
    },
    sortedAmendments () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.changeset.expression.appliedAmendments.sort((a, b) => {
        const toc = this.changeset.expression.expression.toc;

        // sorty by TOC entry order
        const tocA = toc.closestTocEntryForId(a.elementId || a.afterElementId);
        const tocB = toc.closestTocEntryForId(b.elementId || b.afterElementId);
        return tocA.sortKey - tocB.sortKey;
      });
    }
  },
  watch: {
    expanded (index) {
      if (index !== undefined) {
        // bring the selected value into view
        this.$emit('selected', this.changeset.expression.appliedAmendments[index]);
      }
    }
  },
  methods: {
    addAmendment (amendment) {
      this.changeset.expression.addAmendment(amendment);
      this.amendmentsDialog = false;
    },
    removeAmendment (amendment) {
      this.changeset.expression.removeAmendment(amendment);
      this.expanded = null;
    }
  }
};
</script>
