<template>
  <div>
    <v-navigation-drawer clipped app right color="grey lighten-3">
      <ChangesetSidebar :changeset="changeset" @selected="scrollToAmendment"/>
    </v-navigation-drawer>

    <v-container fluid>
      <ChangesetContent ref="content" :changeset="changeset" :expression="changeset.expression" />
    </v-container>
  </div>
</template>

<script>
import './akomantoso.scss';
import ChangesetSidebar from '@/components/ChangesetSidebar';
import ChangesetContent from '@/components/ChangesetContent';
import { changesets } from '@/data/changesets';

export default {
  name: 'Amendments',
  components: { ChangesetContent, ChangesetSidebar },
  computed: {
    changeset () {
      return changesets.find(c => c.id.toString() === this.$route.params.id);
    }
  },
  methods: {
    scrollToAmendment (amendment) {
      if (amendment) {
        this.$refs.content.scrollToAmendment(amendment);
      }
    }
  }
};
</script>
