<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card>
      <v-card-title>Find the version applicable on a date</v-card-title>

      <v-card-text>
        <v-text-field
          v-model="date"
          label="Choose a date"
          prepend-icon="mdi-calendar"
        ></v-text-field>

        <div class="text-center">
          <v-date-picker v-model="date" no-title scrollable></v-date-picker>
        </div>

        <div v-if="expression">
          The version applicable on {{ date }} is {{ expression.expression_date }}.

          <div class="text-center mt-3">
            <v-btn v-if="href" :to="href" @click="dialog = false" color="primary">
              Use version {{ expression.expression_date }}
            </v-btn>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Marpol } from '../data/marpol';
import { DateTime } from 'luxon';

export default {
  name: 'VersionChooser',
  data: () => ({
    dialog: false,
    date: DateTime.now().toISODate()
  }),
  computed: {
    expression () {
      if (this.date) {
        // find closest expression at or after date - expressions are oldest first
        const expressions = Marpol.expressions;
        for (let i = expressions.length - 1; i >= 0; i--) {
          if (this.date >= expressions[i].expression_date) {
            return expressions[i];
          }
        }
      }
      return null;
    },
    href () {
      if (this.expression) {
        return '/instrument' + this.expression.expression_frbr_uri;
      }
      return null;
    }
  },
  methods: {
    open () {
      this.dialog = true;
    }
  }
};
</script>

<style scoped>

</style>
