import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: h => h(App),
  created () {
    if (sessionStorage.redirect) {
      // This is set by 404.html when GitHub pages serves a site directly from a full URL. Fetch, delete and redirect.
      const redirect = sessionStorage.redirect;
      delete sessionStorage.redirect;
      this.$router.push(redirect);
    }
  }
}).$mount('#app');
