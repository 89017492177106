import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import './decorations.scss';
import debounce from 'lodash/debounce';
import InternalRef from './InternalRef.vue';
import IncomingRefs from './IncomingRefs.vue';
import InfoBox from './InfoBox.vue';
import Footnote from './Footnote.vue';
import Commencement from './Commencement.vue';
import ExternalForm from './ExternalForm.vue';
import Diffset from './Diffset.vue';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import Papa from 'papaparse';
import { diffsetsForExpression, groupDiffsetsByElement } from '@/data/marpol';

// the Breakpoint service make Vue components VERY slow, so we remove it here because we don't use it
export const vuetify = new Vuetify({});
vuetify.installed = vuetify.installed.filter(x => x !== 'breakpoint');
delete vuetify.framework.breakpoint;

/**
 * A decoration that binds to the mouseover of certain elements and shows a popup containing the extracted
 * contents of another element (the ref element).
 */
class PopupRefs {
  constructor (root, aknDoc) {
    this.root = root;
    this.aknDoc = aknDoc;
    this.createWrapper();
    this.createTippy();
  }

  createWrapper () {
    this.wrapper = document.createElement('div');
    this.wrapper.className = 'akoma-ntoso';
  }

  createTippy () {
    this.tippy = tippy(this.getTriggerElements(), {
      appendTo: this.root.parentElement,
      content: '',
      hideOnClick: true,
      interactive: true,
      maxWidth: 450,
      onTrigger: this.onTrigger.bind(this),
      onUntrigger: this.onUntrigger.bind(this),
      theme: 'light-border'
    });
  }

  getTriggerElements () {
    return this.root.querySelectorAll('a.akn-ref[href^="#"]');
  }

  /**
   * Get the reference element referred to by this tippy instance, from the original AKN document.
   * @returns {Element | HTMLElement}
   */
  getReferenceElement (tippy) {
    const id = tippy.reference.getAttribute('href').slice(1);
    return this.aknDoc.getElementById(id);
  }

  onTrigger (tippy, event) {
    const provision = this.getReferenceElement(tippy);

    if (provision) {
      const wrapper = this.wrapper.cloneNode(true);
      wrapper.appendChild(provision.cloneNode(true));
      tippy.setContent(wrapper);

      // highlight elsewhere in the document
      const ref = this.root.querySelector(`[id="${provision.id}"]`);
      if (ref) {
        ref.classList.add('secondary-highlight');
      }
    }
  }

  onUntrigger (tippy, event) {
    const provision = this.getReferenceElement(tippy);

    // unhighlight elsewhere in the document
    const ref = this.root.querySelector(`[id="${provision.id}"]`);
    if (ref) {
      ref.classList.remove('secondary-highlight');
    }
  }
}

class PopupDefinitionRefs extends PopupRefs {
  getTriggerElements () {
    return this.root.querySelectorAll('.akn-term');
  }

  getReferenceElement (tippy) {
    const term = tippy.reference.getAttribute('data-refersto');
    // find where the term is defined
    const def = this.aknDoc.querySelector(`.akn-def[data-refersto="${term}"]`);
    // find the container that wraps the defined term
    return def.parentElement.closest(`[data-refersto="${term}"]`);
  }
}

/**
 * Helper class to adjust the vertical layout of a collection of decoration content elements, such that they are aligned
 * vertically with their reference elements, but don't overlap each other.
 *
 * Add decorations with add(). Decoration objects must have:
 *
 * * a `ref` attribute, which is the reference element to position the decoration in line with
 * * a `top` setter, which will be called with the new top pixel value
 */
export class DecorationLayout {
  /**
   * @param ref reference element to observe for resizing, and re-run the layout. This MUST have a position style
   *        attribute, such as position: relative;
   */
  constructor (ref) {
    this.ref = ref;
    this.decorations = [];
    // time to wait after resizing (debounce)
    this.wait = 250;
    // vertical buffer between elements
    this.buffer = 10;
    this._primary = null;
    this.tops = null;
  }

  init () {
    this.layout();

    if (window.ResizeObserver) {
      // add observer to re-layout when the containing document changes size, which implies
      // the positions will change
      this.observer = new ResizeObserver(debounce(this.layout.bind(this), this.wait));
      this.observer.observe(this.ref);
    }
  }

  /**
   * Mark a decoration as primary, and lay it out before the others.
   */
  set primary (decoration) {
    this._primary = decoration;
    this.layout();
  }

  layout () {
    // pre-calculate tops
    this.updateTops();

    // sort decorations by ascending ref top
    this.decorations.sort((a, b) => this.tops.get(a.ref) - this.tops.get(b.ref));

    if (this._primary) {
      const ix = this.decorations.indexOf(this._primary);
      if (ix > -1) {
        // layout the primary decoration first
        const top = this.tops.get(this._primary.ref);
        this._primary.top = top;
        // layout the ones going upwards from here
        this.layoutUpwards(ix - 1, top - this.buffer);
        // layout the ones going downwards from here
        this.layoutDownwards(ix + 1, top + this._primary.content.clientHeight + this.buffer);
        return;
      }
    }

    // nothing is primary, go top downwards
    this.layoutDownwards(0, 0);
  }

  layoutUpwards (start, watermark) {
    // layout the decorations from index start, going bottom to top
    for (let i = start; i >= 0; i--) {
      const decoration = this.decorations[i];
      let top = this.tops.get(decoration.ref);
      if (top + decoration.content.clientHeight >= watermark) {
        top = watermark - decoration.content.clientHeight;
      }
      decoration.top = top;
      watermark = top - this.buffer;
    }
  }

  layoutDownwards (start, watermark) {
    // layout the decorations from index start, going top to bottom
    for (let i = start; i < this.decorations.length; i++) {
      const decoration = this.decorations[i];
      const top = Math.max(watermark, this.tops.get(decoration.ref));
      decoration.top = top;
      watermark = top + decoration.content.clientHeight + this.buffer;
    }
  }

  updateTops () {
    this.tops = new WeakMap();

    for (const decoration of this.decorations) {
      if (!this.tops.has(decoration.ref)) {
        this.tops.set(decoration.ref, this.calculateTop(decoration.ref));
      }
    }
  }

  /**
   * Find the top of an element, relative to this.ref.
   * @param element
   * @returns {number}
   */
  calculateTop (element) {
    let top = 0;

    while (element && element !== this.ref) {
      top += element.offsetTop;
      element = element.offsetParent;
    }

    return top;
  }
}

/**
 * Add gutter markers for internal references
 */
// eslint-disable-next-line no-unused-vars
function internalRefDecorations (documentRoot, expression) {
  const Component = Vue.extend(InternalRef);
  const items = [];

  for (const ref of documentRoot.querySelectorAll('a.akn-ref[href^="#"]')) {
    const id = ref.getAttribute('href').slice(1);
    const item = expression.toc.itemsById.get(id);

    // these are hidden when the gutter is visible
    if (ref.closest('.akn-authorialNote')) continue;

    if (id && item) {
      const content = new Component({ vuetify });
      content.item = item;
      content.ref = ref;
      content.$mount();
      items.push(content);
    }
  }

  return items;
}

/**
 * Add gutter markers for footnotes
 */
function footnoteDecorations (documentRoot) {
  const Component = Vue.extend(Footnote);
  const items = [];

  for (const footnote of documentRoot.querySelectorAll('.akn-authorialNote')) {
    const content = new Component({ vuetify });
    content.footnote = footnote.cloneNode(true);

    // ref is the footnote marker, not the footnote content
    const ref = footnote.parentElement.querySelector(`a[href="#${footnote.id}"]`);
    if (ref) {
      content.ref = ref;
      content.$mount();
      items.push(content);
    } else {
      console.warn("Couldn't find footnote parent", footnote);
    }
  }

  return items;
}

/**
 * Add gutter markers for incoming references
 */
function incomingRefDecorations (documentRoot, expression) {
  const Component = Vue.extend(IncomingRefs);
  const incoming = new Map();
  const items = [];

  // group internal references by the elements that they reference to
  for (const ref of documentRoot.querySelectorAll('a.akn-ref[href^="#"]')) {
    const id = ref.getAttribute('href').slice(1);
    if (incoming.has(id)) {
      incoming.get(id).push(ref);
    } else {
      incoming.set(id, [ref]);
    }
  }

  // add a decoration alongside each element that has incoming references
  for (const [id, refs] of incoming) {
    const content = new Component({ vuetify });
    const element = documentRoot.querySelector(`[id="${id}"]`);

    if (element) {
      const references = [];
      const unique = new Set();

      // transform incoming ref links to human friendly titles of where those links
      // are located (ie. the TOC entry that has the incoming link to `element`.
      for (const ref of refs) {
        // get closest TOC entry for this ref
        const tocEntry = expression.toc.closestTocEntry(ref);
        if (tocEntry && !unique.has(tocEntry[1])) {
          references.push(tocEntry[1]);
          unique.add(tocEntry[1]);
        }
      }

      content.references = references;
      content.ref = element;
      content.$mount();
      items.push(content);
    }
  }

  return items;
}

/**
 * Add gutter markers for general information
 */
const _infoDecorations = [];
async function getInfoDecorations (documentRoot) {
  const Component = Vue.extend(InfoBox);

  return new Promise((resolve) => {
    function unpack () {
      const items = [];

      for (const info of _infoDecorations) {
        const ref = documentRoot.querySelector(`[id="${info.element_id}"]`);
        if (ref) {
          const content = new Component({ vuetify });
          content.info = info;
          content.ref = ref;
          content.$mount();
          items.push(content);
        }
      }

      return items;
    }

    if (_infoDecorations.length === 0) {
      // grab info from google sheet
      // https://docs.google.com/spreadsheets/d/1OLygdgMWMaIOp4KCRToTrheo94EzJUSL8B4urYN5WSs/edit#gid=0
      Papa.parse('https://docs.google.com/spreadsheets/d/1OLygdgMWMaIOp4KCRToTrheo94EzJUSL8B4urYN5WSs/gviz/tq?tqx=out:csv&sheet=Sheet1', {
        download: true,
        header: true,
        complete: (results) => {
          for (const info of results.data) {
            _infoDecorations.push(info);
          }
          resolve(unpack());
        }
      });
    } else {
      resolve(unpack());
    }
  });
}

/**
 * Commencement markers in the text itself.
 *
 * This is calculated by looking at the diffsets for the current expression and earlier. For each diffset,
 * we check if the changed element has a remark that includes "inserted". This indicates a new provision.
 * The in-force date is taken to be the expression date of the first expression that included this diffset.
 */
function commencementDecorations (root, expression) {
  const Component = Vue.extend(Commencement);
  const groups = groupDiffsetsByElement(diffsetsForExpression(expression));

  for (const [elementId, diffsets] of groups) {
    let element = root.querySelector(`[id="${elementId}"]`);
    const toc = expression.toc.itemsById.get(elementId);

    if (element && toc) {
      const remark = element.querySelector('.akn-remark');
      if (remark && (remark.textContent.indexOf('inserted') > -1 || remark.textContent.indexOf('added') > -1)) {
        // provision was inserted
        const content = new Component({ vuetify });
        const dates = diffsets.map(d => d.expression_frbr_uri.substring(d.expression_frbr_uri.indexOf('@') + 1)).sort();
        content.info = {
          provision_type: toc.type === 'part' ? 'regulation' : toc.type,
          date: dates[0]
        };
        content.$mount();

        if (element.classList.contains('akn-attachment')) {
          element = element.querySelector('.akn-mainBody');
          element.insertBefore(content.$el, element.firstElementChild);
        } else {
          element.appendChild(content.$el);
        }
      }
    }
  }
}

/**
 * Help user fill in forms externally
 */
function formDecorations (root, expression) {
  const Component = Vue.extend(ExternalForm);
  const forms = [{
    element_id: 'att_1__dvs_nn_1'
  }, {
    element_id: 'att_1__dvs_nn_2'
  }];

  for (const form of forms) {
    const element = root.querySelector(`[id="${form.element_id}"]`);

    if (element) {
      const content = new Component({ vuetify });
      content.form = form;
      content.$mount();

      const ref = element.querySelector('h2, h3');
      element.insertBefore(content.$el, ref.nextElementSibling);
    }
  }
}

/**
 * Add decorations to show differences for amended provisions.
 */
export function diffDecorations (root, expression) {
  const Component = Vue.extend(Diffset);
  const groups = groupDiffsetsByElement(diffsetsForExpression(expression));

  for (const [elementId, group] of groups) {
    const ref = root.querySelector(`[id="${elementId}"]`);

    if (ref) {
      const remarks = [...ref.querySelectorAll('.akn-remark')];

      if (remarks.length) {
        const remark = remarks[0];
        const content = new Component({ vuetify });
        content.diffsets = group;
        content.$mount();

        // insert a button to trigger the dialog into the remark in the provision
        const button = document.createElement('button');
        button.className = 'v-btn mx-3 theme--light v-size--x-small blue lighten-2';
        button.style = 'font-style: normal';
        button.type = 'button';

        const text = document.createElement('span');
        text.innerText = 'What changed?';
        text.className = 'v-btn__content text-button';
        text.addEventListener('click', (e) => {
          e.preventDefault();
          e.stopPropagation();
          content.show();
        });
        button.appendChild(text);

        remark.insertBefore(button, remark.childNodes[remark.childNodes.length - 1]);
      }
    }
  }
}

/**
 * Add AKN decorations to the tree anchored at root.
 * @param root
 */
export function addDecorations (root, gutter, expression) {
  // eslint-disable-next-line no-new
  new PopupRefs(root, expression.aknDoc);
  // eslint-disable-next-line no-new
  new PopupDefinitionRefs(root, expression.aknDoc);

  // in-text decorations
  commencementDecorations(root, expression);
  formDecorations(root, expression);
  diffDecorations(root, expression);

  // gutter decorations
  let items = [];
  items = items.concat(footnoteDecorations(root));
  // items = items.concat(internalRefDecorations(root, expression));
  items = items.concat(incomingRefDecorations(root, expression));
  return items;
}

export async function getAsyncDecorations (root) {
  return await getInfoDecorations(root);
}
