<template>
  <v-container>
    <h2 class="mb-3">Draft amendments to MARPOL Annex VI</h2>

    <p>This demo mimics the process of preparing draft amendments during meetings, and applying multiple evolving amendments to the principal text.</p>

    <p>It shows three iterations of draft amendments relating to fuel oil sampling and testing, made between 2018 and 2019:</p>

    <v-row>
      <v-col cols="12" md="6">
        <v-simple-table dense class="mb-4">
          <template v-slot:default>
            <tbody>
              <tr>
                <th>Date</th>
                <th>Version</th>
              </tr>
              <tr>
                <td>2018-07-18</td>
                <td>Original submission to PPR 6</td>
              </tr>
              <tr>
                <td>2019-03-26</td>
                <td>Outcomes of the discussions at PPR 6</td>
              </tr>
              <tr>
                <td>2019-07-07</td>
                <td>Outcomes of the discussions at MEPC 74/18</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <h3 class="mt-6 mb-3">What to look for</h3>

    <ol>
      <li>Click on an amendment to view the changes.</li>
      <li>Changes can be compared with the principal text, or the previous amendment.</li>
      <li>The draft <b>amending text</b> is generated automatically.</li>
      <li>Add or change amendments to see it in action.</li>
    </ol>

    <h2 class="mt-12">Draft amendments</h2>

    <v-timeline class="mt-3">
      <v-timeline-item
        v-for="chg in sortedChangesets"
        :key="chg.id"
        color="orange lighten-2"
        small
        left
        fill-dot
      >
        <template v-slot:opposite>{{chg.date}}</template>
        <v-card color="orange lighten-4">
          <v-card-title>
            <router-link :to="`/changesets/${chg.id}`">{{ chg.doc_id || "No title" }}</router-link>
            <v-spacer />
            <v-btn text color="red" @click="deleteChangeset(chg)">Delete</v-btn>
          </v-card-title>
          <v-card-text>{{ chg.expression.appliedAmendments.length }} changes</v-card-text>
          <v-card-actions>
            <v-dialog width="1024" v-model="amendmentTextDialog[chg.id]">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on">Amending text</v-btn>
              </template>

              <DraftAmendingText :changeset="chg" @closed="amendmentTextDialog[chg.id] = false" />
            </v-dialog>

            <v-spacer />
            <v-btn outlined @click="addChangeset(chg)">Create copy</v-btn>
          </v-card-actions>
        </v-card>
      </v-timeline-item>

      <v-timeline-item
        v-if="changesets.length === 0"
        left
        small
        fill-dot
        color="blue lighten-2"
        class="mb-6"
      >
        <v-btn color="primary" @click="addChangeset()">Add draft changes</v-btn>
      </v-timeline-item>

      <v-timeline-item
        color="green lighten-2"
        class="mt-12"
        fill-dot
        right
      >
        <h3>{{ expression.expression_date}}</h3>
            {{ expression.title }}
      </v-timeline-item>

    </v-timeline>
  </v-container>
</template>

<script>
import { Marpol } from '@/data/marpol';
import { AmendableExpression, amendments, variants } from '@/data/amendments';
import { changesets, newChangeset, deleteChangeset } from '@/data/changesets';
import DraftAmendingText from '@/components/DraftAmendingText';
import router from '@/router';

// setup the pre-prepped series of changesets for the Fuel oil amendments
const expression = Marpol.expressions.find(e => e.expression_date === '2018-03-01');

// As submitted to PPR 6 (based on PPR 6/8)
let chg = newChangeset();
chg.date = '2018-07-18';
chg.doc_id = 'Submitted to PPR 6';
chg.expression = new AmendableExpression(expression);
chg.expression.addAmendment(amendments.find(a => a.id === 1));
chg.expression.addAmendment(amendments.find(a => a.id === 2));

// Outcome of PPR 6 (based on PPR 6/WP.5)
chg = newChangeset(chg);
chg.date = '2019-03-26';
chg.doc_id = 'PPR 6/20/Add.1';
chg.expression.appliedAmendments.find(a => a.id === 1).setVariant(variants.get('1.1'));
chg.expression.appliedAmendments.find(a => a.id === 2).setVariant(variants.get('2.1'));
chg.expression.addAmendment(amendments.find(a => a.id === 4));

// Outcome of MEPC 74
chg = newChangeset(chg);
chg.date = '2019-07-17';
chg.doc_id = 'MEPC 74/18';
chg.expression.appliedAmendments.find(a => a.id === 2).setVariant(variants.get('2.2'));
chg.expression.addAmendment(amendments.find(a => a.id === 5));

export default {
  name: 'Changesets',
  components: { DraftAmendingText },
  data: () => ({
    expression,
    changesets,
    amendmentTextDialog: {}
  }),
  computed: {
    sortedChangesets () {
      return [...this.changesets].sort((a, b) => b.date.localeCompare(a.date));
    }
  },
  methods: {
    addChangeset (original) {
      const chg = newChangeset(original);
      if (!chg.expression) chg.expression = new AmendableExpression(Marpol.latest_expression);
      router.push('/changesets/' + chg.id);
    },
    deleteChangeset (chg) {
      deleteChangeset(chg);
    }
  }
};
</script>
