<template>
  <v-card>
    <v-card-title class="grey lighten-3">Draft amendment text</v-card-title>
    <div style="overflow-y: auto; max-height: 70vh" class="pt-3">
      <div class="akoma-ntoso limit-document-width diffset" v-html="aknHtml"></div>
    </div>
    <v-divider />
    <v-card-actions>
      <v-switch v-if="changeset.parent" v-model="diffPrevious" label="Compare with previous draft changes" />
      <v-spacer />
      <v-btn text color="primary" @click="closed">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { getDiff } from '@/data/amendments';
import './amendments.scss';

export default {
  name: 'ChangesetText',
  props: ['changeset'],
  data: () => ({
    diffPrevious: false,
    aknHtml: ''
  }),
  mounted () {
    this.updateAknHtml();
  },
  watch: {
    diffPrevious () {
      this.updateAknHtml();
    }
  },
  methods: {
    updateAknHtml () {
      if (this.diffPrevious) {
        // diff against previous version
        getDiff(
          this.changeset.parent.generateAmendmentHtml().outerHTML,
          this.changeset.generateAmendmentHtml().outerHTML).then((html) => {
          this.aknHtml = html;
        });
      } else {
        this.aknHtml = this.changeset.generateAmendmentHtml().outerHTML;
      }
    },
    closed () {
      this.$emit('closed');
    }
  }
};
</script>
