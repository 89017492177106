<template>
  <div class="text-left">
    <v-menu
      offset-y
      z-index="10000"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mt-3 mr-3"
          elevation="0"
          text
          height="40"
          v-bind="attrs"
          v-on="on"
        >
          Preferences
        </v-btn>
      </template>

      <v-card class="pa-3">
        <h3 class="text-left mb-3">Preferences</h3>
        <v-divider></v-divider>
        <v-checkbox v-model="sansSerifFont" label="Use san-serif font" @change="save" />
        <div>Font size</div>
        <v-text-field
          v-model="fontSize"
          solo-inverted
          flat
          min="12"
          max="30"
          type="number"
          @change="save"
        />
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { saveUser, user } from '../data/user';

export default {
  name: 'Preferences',
  data () {
    return user.preferences;
  },
  methods: {
    save: function () {
      saveUser();
    }
  }
};
</script>
