<template>
  <div>
    <v-radio-group v-if="amendment.getVariants().length > 0" v-model="variant">
      <v-radio value="-" label="As submitted" />
      <v-radio
        v-for="variant in amendment.getVariants()"
        :key="variant.id"
        :label="variant.title"
        :value="variant.id"
      />
    </v-radio-group>

    <div class="pt-3 text-right">
      <v-btn text color="red" @click="remove()">Remove</v-btn>
    </div>
  </div>
</template>

<script>
import { variants } from '@/data/amendments';

export default {
  name: 'AmendmentPanel',
  props: ['amendment', 'changeset'],
  data: () => ({
    variant: '-'
  }),
  mounted () {
    this.variant = this.amendment.variant ? this.amendment.variant.id : '-';
  },
  watch: {
    variant (value) {
      if (value === '-') value = null;
      this.amendment.setVariant(variants.get(value));
      this.changeset.expression.applyAmendments();
    }
  },
  methods: {
    remove () {
      if (confirm('Really remove this amendment?')) {
        this.$emit('removed', this.amendment);
      }
    }
  }
};
</script>

<style scoped>

</style>
