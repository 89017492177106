<template>
  <v-container>
    <v-alert v-if="!passwordValidity" type="error" dense border="left" dismissible >Incorrect password. Please try again.</v-alert>
    <v-spacer style="height: 150px;"></v-spacer>
    <v-card
      class="mx-auto"
      max-width="400px"
    >
      <v-card-text>
        <v-text-field
          v-model="password"
          label="Password"
          :rules="passwordRules"
          prepend-icon="mdi-lock-outline"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          required
          :type="inputFieldType"
          @keyup.enter="submit"
          @click:append="togglePasswordVisibility"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="!password ? true : false"
          @click="submit"
        >
          Login
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { lastPath } from '../data/lastPath';

export default {
  name: 'Login',
  data () {
    return {
      password: '',
      passwordValidity: true,
      showPassword: false,
      inputFieldType: 'password'
    };
  },
  methods: {
    submit () {
      if (this.password === 'demo.password') {
        localStorage.setItem('loggedIn', true);
        if (lastPath.path) {
          this.$router.push(lastPath);
          lastPath.path = '';
        } else {
          this.$router.push('/');
        }
      } else this.passwordValidity = false;
    },
    togglePasswordVisibility () {
      this.showPassword = !this.showPassword;
      this.inputFieldType = this.inputFieldType === 'text' ? 'password' : 'text';
    }
  },
  computed: {
    passwordRules () {
      return [
        value => !!value || 'Password is required'
      ];
    }
  }
};
</script>
