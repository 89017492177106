<template>
  <div>
    <v-navigation-drawer clipped app :value="chrome.expandDrawer" color="grey lighten-3">
      <TOCMenu :expression="expression" :active="active"/>
    </v-navigation-drawer>

    <v-container fluid>
      <Toolbar :expression="expression" />

      <div class="primary-document-container" :class="containerClasses">
        <article :style="styles" :class="classes" class="akoma-ntoso primary-document show-terms flag-internal-refs">
          <div class="limit-document-width" ref="container"></div>
        </article>
        <Gutter ref="gutter" :expression="expression" :items="gutterItems"/>
      </div>
    </v-container>
  </div>
</template>

<script>
import TOCMenu from '../components/TOCMenu.vue';
import Gutter from '../components/Gutter';
import Toolbar from '../components/Toolbar';
import { chrome, matchWindowSize } from '../data/chrome';
import { user } from '../data/user';
import { Marpol } from '../data/marpol';
import './akomantoso.scss';
import { addDecorations, getAsyncDecorations } from '../decorations/decorations';

/**
 * Extract ids from nested objects
 * @param items
 * @returns {[]}
 */
function idsInToc (items) {
  let ids = [];

  for (const item of items) {
    if (item.id) {
      ids.push(item.id);
    }

    if (item.children) {
      ids = ids.concat(idsInToc(item.children));
    }
  }

  return ids;
}

export default {
  name: 'Instrument',
  showDrawerToggle: true,
  components: {
    TOCMenu,
    Gutter,
    Toolbar
  },
  watch: {
    $route (to, from) {
      if (to.params.frbr_uri !== from.params.frbr_uri) {
        this.$refs.container.innerHTML = '';
        this.insertAkn();
      }
    }
  },
  mounted () {
    // inject the html if it's not already there
    if (!this.$refs.container.firstElementChild) {
      this.insertAkn();
    }
    matchWindowSize();
  },
  methods: {
    insertAkn () {
      this.$refs.container.appendChild(this.expression.aknRoot.cloneNode(true));
      this.addDecorations();
      this.observeProvisions();
    },
    addDecorations () {
      this.$refs.gutter.reset(this.$refs.container.parentElement);
      this.gutterItems = addDecorations(this.$refs.container.parentElement, this.$refs.gutter, this.expression);
      // we need to load async decorations a bit differently
      getAsyncDecorations(this.$refs.container.parentElement).then((items) => {
        for (const item of items) {
          this.gutterItems.push(item);
        }
      });
    },
    observeProvisions () {
      // IE doesn't have this
      if (!window.IntersectionObserver) return;

      // add scrolling observer
      if (this.observer) {
        this.observer.disconnect();
      }

      // when a provision scrolls into view, activate it in the TOC
      this.observer = new IntersectionObserver(entries => {
        const active = entries.filter(e => e.isIntersecting);
        if (active.length) {
          // firefox doesn't like this
          if (navigator.userAgent.indexOf('Firefox') === -1) {
            this.active = [active[0].target.id];
          }
        }
      }, {
        // this creates a bounding box just below the fixed header
        rootMargin: '-125px 0px -70% 0px',
        threshold: [0.1]
      });

      // watch all elements in the toc
      const ids = idsInToc(this.expression.toc.items);
      for (const id of ids) {
        const elem = this.$refs.container.querySelector(`[id="${id}"]`);
        if (elem) {
          this.observer.observe(elem);
        }
      }
    }
  },
  computed: {
    expression () {
      const frbrUri = '/' + this.$route.params.frbr_uri;
      return Marpol.expressions.find(e => e.expression_frbr_uri === frbrUri);
    },
    styles: function () {
      return 'fontSize: ' + this.user.preferences.fontSize + 'px';
    },
    classes: function () {
      return {
        'pref-sans-serif-font': this.user.preferences.sansSerifFont
      };
    },
    containerClasses: function () {
      return {
        'hide-incoming-refs': !this.user.settings.incomingRefs,
        'hide-internal-refs': !this.user.settings.internalRefs,
        'hide-infoboxes': !this.user.settings.infoboxes,
        'hide-commencements': !this.user.settings.commencements,
        'footnotes-aside': this.user.settings.footnotesAside
      };
    }
  },
  data: () => ({
    chrome,
    active: [],
    user,
    gutterItems: []
  })
};
</script>
