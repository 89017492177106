<template>
  <div class="text-center">
    <v-dialog
      max-width="500"
      v-model="dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="!user.premium"
          class="mt-3 mr-3"
          elevation="0"
          text
          v-bind="attrs"
          v-on="on"
        >Upgrade Subscription</v-btn>
        <v-btn
          v-else
          class="mt-3 mr-3"
          elevation="0"
          text
          v-bind="attrs"
          v-on="on"
          @click="subscribed = true"
        >Your Subscription</v-btn>
      </template>

      <v-card v-if="!subscribed">
        <v-card-title class="primary white--text">
          Upgrade To Premium
        </v-card-title>

        <v-card-text class="mt-5">
          <v-alert type="warning" class="mt-3">
            Premium features are for demonstration purposes only.
          </v-alert>

          <div v-if="user.premium">
            You've upgraded successfully!
          </div>
          <div v-else>
            A premium subscription provides enhanced functionality, including:

            <ul>
              <li>Supplementary information such as Unified Interpretations</li>
              <li>Improved references</li>
              <li>Details of in-force dates</li>
            </ul>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
            v-show="!user.premium"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="onUpgrade"
            v-show="!user.premium"
          >
            Upgrade
          </v-btn>
          <v-btn
            color="primary"
            @click="dialog = false"
            v-show="user.premium"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="subscribed">
        <v-card-title class="primary white--text">
          Downgrade Subscription
        </v-card-title>

        <v-card-text class="mt-5">
          {{user.premium ? "Are you sure you want to downgrade your subscription?" : "Your subscription has been downgraded."}}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
            v-show="user.premium"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="onDowngrade"
            v-show="user.premium"
          >
            Downgrade
          </v-btn>
          <v-btn
            color="primary"
            @click="onClose"
            v-show="!user.premium"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { downgradeUser, saveUser, upgradeUser, user } from '../data/user';
export default {
  name: 'PremiumDialog',
  data () {
    return {
      dialog: false,
      subscribed: false,
      user
    };
  },
  methods: {
    onUpgrade () {
      upgradeUser();
      saveUser();
    },
    onDowngrade () {
      downgradeUser();
      saveUser();
    },
    onClose () {
      this.subscribed = false;
      this.dialog = false;
    }
  }
};
</script>
