import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Instrument from '../views/Instrument.vue';
import History from '../views/History.vue';
import Login from '../views/Login.vue';
import Changeset from '../views/Changeset.vue';
import Changesets from '../views/Changesets.vue';
import { chrome } from '../data/chrome';
import { lastPath } from '../data/lastPath';
import { Marpol } from '../data/marpol';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/instrument',
    redirect: '/instrument' + Marpol.latest_expression.expression_frbr_uri
  },
  {
    path: '/instrument/:frbr_uri+',
    name: 'Instrument',
    component: Instrument,
    meta: {
      requiresAuth: true,
      guide: 'reading'
    }
  },
  {
    path: '/history',
    name: 'History',
    component: History,
    meta: {
      requiresAuth: true,
      guide: 'reading'
    }
  },
  {
    path: '/changesets',
    name: 'Draft changes',
    component: Changesets,
    meta: {
      requiresAuth: true,
      guide: 'amending'
    }
  },
  {
    path: '/changesets/:id',
    name: 'Draft change',
    component: Changeset,
    meta: {
      requiresAuth: true,
      guide: 'amending'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      guest: true
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPositions) {
    // don't automatically scroll to a hash position
    return {};
  }
});

router.beforeResolve((to, from, next) => {
  // detect whether or not the drawer toggle should be shown, based on the matched component
  const component = to.matched.length > 0 ? to.matched[0].components.default : null;
  chrome.showDrawerToggle = !!(component ? component.showDrawerToggle : false);
  chrome.guide = to.meta.guide;
  next();
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('loggedIn') == null) {
      lastPath.setPath(to.fullPath);
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      });
    } else next();
  } else if (to.matched.some(record => record.meta.guest)) {
    if (localStorage.getItem('loggedIn') == null) {
      next();
    } else {
      next({ name: 'Home' });
    }
  } else next();
});

export default router;
