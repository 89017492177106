<template>
  <div>
    <div class="d-flex">
      <div>Find provisions applicable to ship details</div>
      <v-spacer></v-spacer>
      <v-divider light class="mx-3" vertical></v-divider>
      <v-icon @click="close">mdi-close</v-icon>
    </div>

    <div class="pt-4">
      <v-text-field
        v-model="grossTonnage"
        filled
        min="0"
        max="10000"
        type="number"
        label="Gross tonnage"
      />

      <v-text-field
        v-model="constructionDate"
        filled
        type="date"
        prepend-icon="mdi-calendar"
        label="Construction date"
      />
    </div>

    <div class="pt-4 d-flex">
      <v-btn depressed color="primary" @click="search">Find provisions</v-btn>

      <div v-if="marks.length > 0" class="d-flex">
        <div class="text-caption ma-2">{{ currentIndex + 1 }}/{{ marks.length }}</div>

        <v-icon @click="jumpToPrevious" active-class="">mdi-chevron-up</v-icon>
        <v-icon @click="jumpToNext">mdi-chevron-down</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import Papa from 'papaparse';

export default {
  name: 'Applicability',
  props: ['expression'],
  data: () => ({
    marks: [],
    currentIndex: 0,
    grossTonnage: 0,
    constructionDate: ''
  }),
  mounted () {
    this.document = document.querySelector('.primary-document');
    this.criteria = this.getCriteria();
  },
  methods: {
    jumpTo () {
      if (this.marks.length > 0) {
        const currentMark = this.marks[this.currentIndex];
        this.marks.forEach(mark => mark.classList.remove('current'));
        if (currentMark) {
          currentMark.classList.add('current');
          const position = currentMark.getBoundingClientRect().top + window.scrollY;
          window.scrollTo(0, position - 230);
        }
      }
    },
    jumpToPrevious () {
      if (this.marks.length > 0) {
        this.currentIndex += -1;
        if (this.currentIndex < 0) {
          this.currentIndex = this.marks.length - 1;
        }
        if (this.currentIndex > this.marks.length - 1) {
          this.currentIndex = 0;
        }
        this.jumpTo();
      }
    },
    jumpToNext () {
      if (this.marks.length > 0) {
        this.currentIndex += 1;
        if (this.currentIndex < 0) {
          this.currentIndex = this.marks.length - 1;
        }
        if (this.currentIndex > this.marks.length - 1) {
          this.currentIndex = 0;
        }
        this.jumpTo();
      }
    },
    reset () {
      this.currentIndex = 0;
      this.document.classList.remove('dimmed');
      this.document.querySelectorAll('.not-dimmed').forEach(x => x.classList.remove('not-dimmed'));
      this.marks = [];
    },
    search () {
      this.reset();
      this.document.classList.add('dimmed');

      // what are we searching for?
      const values = {};

      if (this.grossTonnage.length > 0 && this.grossTonnage !== '0') {
        values.grossTonnage = parseInt(this.grossTonnage);
      }

      if (this.constructionDate.length > 0) {
        values.constructionDate = this.constructionDate;
      }

      function match (item) {
        const matches = ['grossTonnage', 'constructionDate'].map((attr) => {
          const val = values[attr];
          if (val && item[attr]) {
            // min and max
            return (val >= item[attr][0] && val <= item[attr][1]);
          }
          return null;
        });

        // it matches if any match is true and no match is false (nulls are ignored)
        return matches.some(m => m === true) && !matches.some(m => m === false);
      }

      // mark matching provisions
      this.marks = [];
      for (const item of this.criteria.filter(match.bind(this))) {
        const ref = document.querySelector(`[id="${item.elementId}"]`);
        if (ref) {
          ref.classList.add('not-dimmed');
          this.marks.push(ref);
        }
      }

      this.jumpTo();
    },
    close () {
      this.$emit('closed');
    },
    getCriteria () {
      const criteria = [];

      // grab info from google sheet
      // https://docs.google.com/spreadsheets/d/1OLygdgMWMaIOp4KCRToTrheo94EzJUSL8B4urYN5WSs/edit#gid=0
      Papa.parse('https://docs.google.com/spreadsheets/d/1OLygdgMWMaIOp4KCRToTrheo94EzJUSL8B4urYN5WSs/gviz/tq?tqx=out:csv&sheet=Applicability', {
        download: true,
        header: true,
        complete: (results) => {
          results.data.forEach(criterion => {
            const newCriteria = {
              elementId: criterion.element_id
            };
            if (criterion.constructionStartDate) {
              newCriteria.constructionDate = [criterion.constructionStartDate, criterion.constructionEndDate];
            }
            if (criterion.minGrossTonnage) {
              newCriteria.grossTonnage = [Number(criterion.minGrossTonnage), Number(criterion.maxGrossTonnage)];
            }
            criteria.push(newCriteria);
          });
        }
      });
      return criteria;
    }
  },
  watch: {
    expression () {
      // new expression, reset
      this.reset();
    }
  }
};
</script>
