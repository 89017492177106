<template>
  <v-app>
    <v-system-bar app height="40px" color="yellow">
      <v-spacer></v-spacer>
      <span>Demonstration purposes only. Do not use this information.</span>
      <v-spacer></v-spacer>
    </v-system-bar>

    <v-app-bar app color="primary" dark clipped-left clipped-right>
      <v-app-bar-nav-icon v-if="chrome.showDrawerToggle" @click.stop="chrome.expandDrawer = !chrome.expandDrawer"></v-app-bar-nav-icon>

      <v-tabs>
        <v-tab to="/">Home</v-tab>
        <v-tab to="/instrument">MARPOL Annex VI</v-tab>
        <v-tab to="/history">History</v-tab>
        <v-tab to="/changesets">Draft changes</v-tab>
        <v-spacer></v-spacer>
        <video-guide></video-guide>
        <v-spacer></v-spacer>
        <preferences v-if="user.premium" />
        <premium-dialog></premium-dialog>
      </v-tabs>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Preferences from './components/Preferences.vue';
import PremiumDialog from './components/PremiumDialog.vue';
import VideoGuide from './components/VideoGuide.vue';
import { chrome } from './data/chrome';
import { user } from './data/user';

export default {
  components: { VideoGuide, PremiumDialog, Preferences },
  name: 'App',
  data: () => ({
    chrome,
    user
  })
};
</script>
