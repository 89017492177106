/**
 * Scroll the page to the element with the given id, taking into account the header padding.
 */

export function scrollToId (id) {
  for (const el of document.querySelectorAll('.jump-target')) {
    el.classList.remove('jump-target');
  }

  const element = document.getElementById(id);
  if (element) {
    element.classList.add('jump-target');
    const top = element.getBoundingClientRect().top + window.scrollY;
    window.scrollTo(0, top - 170);
  }
}
