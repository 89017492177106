const diffCache = new Map();

export async function getDiff (before, after) {
  const key = before + after;
  if (diffCache.has(key)) {
    return diffCache.get(key);
  }

  const response = await fetch('https://9fuu95j02k.execute-api.eu-west-1.amazonaws.com/akn-diff', {
    method: 'POST',
    body: JSON.stringify({
      before: before,
      after: after
    })
  });
  if (response.status === 200) {
    const data = await response.json();
    // if no data, they're the same
    const diff = data.html_diff ? data.html_diff : after;
    diffCache.set(key, diff);
    return diff;
  }
}

export class AmendableExpression {
  constructor (expression) {
    this.expression = expression;
    this.pristine = expression.aknRoot;
    // amended root element
    this.element = null;
    // amendments that have been applied to this element
    this.appliedAmendments = [];
    // rebuild
    this.applyAmendments();
  }

  clone () {
    const expr = new AmendableExpression(this.expression);
    expr.appliedAmendments = this.appliedAmendments.map(a => a.clone());
    expr.applyAmendments();
    return expr;
  }

  addAmendment (amendment) {
    this.appliedAmendments.push(amendment.clone());
    this.applyAmendments();
  }

  removeAmendment (amendment) {
    this.appliedAmendments.splice(this.appliedAmendments.indexOf(amendment), 1);
    this.applyAmendments();
  }

  /**
   * Apply amendments to the pristine document.
   */
  applyAmendments () {
    this.element = this.pristine.cloneNode(true);

    for (const amendment of this.appliedAmendments) {
      this.applyAmendment(amendment);
    }
  }

  /**
   * Update our element by appling this amendment.
   */
  applyAmendment (amendment) {
    let after = false;
    let element = null;

    if (amendment.elementId) {
      // replaced element
      element = this.element.querySelector(`[id="${amendment.elementId}"]`);
    } else {
      // inserted new element
      element = this.element.querySelector(`[id="${amendment.afterElementId}"]`);
      after = true;
    }

    if (element) {
      if (after) {
        element.insertAdjacentElement('afterend', amendment.element.cloneNode(true));
      } else {
        element.parentNode.replaceChild(amendment.element.cloneNode(true), element);
      }
    }
  }

  /**
   * Calculate an HTML diff between the original document before and after this amendment.
   * @returns {Promise<string>}
   */
  async diffAmendment (amendment) {
    if (amendment.elementId) {
      const before = this.pristine.querySelector(`[id="${amendment.elementId}"]`);
      if (before) {
        return getDiff(before.outerHTML, amendment.element.outerHTML);
      }
    }

    // element was added
    return '<div class="ins">' + amendment.element.outerHTML + '</div>';
  }
}

export class Amendment {
  constructor (id, pristine, elementId, afterElementId, title) {
    this.id = id;

    // original, unaltered element
    if ((typeof pristine) === 'string') {
      pristine = new DOMParser().parseFromString(pristine, 'text/html').body.firstElementChild;
    }
    this.pristine = pristine;
    this.element = pristine;
    this.elementId = elementId;
    this.afterElementId = afterElementId;
    this.title = title;
    this.variant = null;
  }

  clone () {
    const amendment = new Amendment(
      this.id, this.pristine.cloneNode(true), this.elementId, this.afterElementId, this.title);
    amendment.setVariant(this.variant);
    return amendment;
  }

  getAmendedElement (root) {
    if (this.elementId) {
      return root.querySelector(`[id="${this.elementId}"]`);
    } else {
      const ref = root.querySelector(`[id="${this.afterElementId}"]`);
      if (ref) {
        return ref.nextElementSibling;
      }
    }
  }

  getDescription (expression) {
    const provision = expression.expression.toc.closestMajorTocEntryForId(this.elementId || this.afterElementId);

    if (this.elementId) {
      return `${provision.title} is replaced by`;
    } else {
      return `The following is inserted after ${provision.title}`;
    }
  }

  getVariants () {
    return [...variants.values()].filter(v => v.amends === this.id);
  }

  setVariant (variant) {
    if (variant) {
      this.variant = variant;
      this.element = new DOMParser().parseFromString(variant.html, 'text/html').body.firstElementChild;
    } else {
      // clear it
      this.variant = null;
      this.element = this.pristine;
    }
  }

  getKey () {
    return this.id + '-' + (this.variant ? this.variant.id : '');
  }

  /**
   * Diff the current variant of this amendment against previous, which is also an amendment object (possibly
   * with a variant).
   */
  async diffAmendment (previous) {
    return getDiff(previous.element.outerHTML, this.element.outerHTML);
  }

  htmlForAmendingText () {
    const html = this.element.cloneNode(true);
    // add a quote at the end of the last akn-p tag
    let p;
    if (html.classList.contains('akn-p')) {
      p = html;
    } else {
      p = [...html.querySelectorAll('.akn-p')].pop() || html;
    }
    let child = p.lastChild;
    while (child && child.nodeType !== child.TEXT_NODE) child = child.lastChild;
    if (child) {
      child.textContent = child.textContent + '"';
    }

    return html.outerHTML;
  }
}

const rawAmendments = [{
  id: 1,
  title: 'R2 - Definitions',
  elementId: null,
  afterElementId: 'chp_1__part_2__para_50',
  html: `
  <div>
    <div class="akn-paragraph" eId="chp_1__part_2__para_51">
      <span class="akn-num">51</span>
      <span class="akn-content">
        <span class="akn-p">
          <span class="akn-def">Sulphur content</span> means the concentration of sulphur in any fuel oil, measured in % m/m as tested in accordance with standard acceptable to the Organization.
        </span>
      </span>
    </div>

    <div class="akn-paragraph" eId="chp_1__part_2__para_52">
      <span class="akn-num">52</span>
      <span class="akn-content">
        <span class="akn-p">
          <span class="akn-def">Low-flashpoint fuel</span> means gaseous or liquid fuel having a flashpoint lower than otherwise permitted under paragraph 2.1.1 of SOLAS regulation II-2/4.
        </span>
      </span>
    </div>
  </div>`
}, {
  id: 2,
  title: 'R14 - Fuel oil sampling and testing',
  afterElementId: 'chp_3__part_14__dvs_nn_3',
  html: `
  <div>
    <section class="akn-division" id="chp_3__part_14__dvs_nn_4">
      <h2>[In-use] [Onboard] fuel oil sampling and testing</h2>
      <section class="akn-paragraph">
        <span class="akn-num">[A1]</span>
        <span class="akn-content">
          <span class="akn-p">If the competent authority of a Party requires the [in-use] [onboard] fuel oil sample to be analysed, it shall be done in accordance with the verification procedure set forth in appendix VI to determine whether the fuel oil being used on board meets the requirements in paragraph 1 or paragraph 4 of this regulation. The [in-use] [onboard] fuel oil sample shall be drawn taking into account the guidelines developed by the Organization.</span>
        </span>
      </section>
    </section>
    <section class="akn-division" id="chp_3__part_14__dvs_nn_5">
      <h2>[In-use] [Onboard] fuel oil sampling point</h2>
      <section class="akn-paragraph">
        <span class="akn-num">[A2]</span>
        <span class="akn-content">
          <span class="akn-p">For each ship subject to regulations 5 and 6 of this Annex, one or more sampling points shall be fitted or designated for the purpose of taking representative samples of the fuel oil being used on board the ship taking into account guidelines developed by the Organization.</span>
        </span>
      </section>
      <section class="akn-paragraph">
        <span class="akn-num">[A3]</span>
        <span class="akn-content">
          <span class="akn-p">For a ship constructed before [entry into force of these requirements], the sampling points referred to in paragraph [A2] shall be fitted or designated not later than the first renewal survey that occurs after [entry into force of these regulations].</span>
        </span>
      </section>
      <section class="akn-paragraph">
        <span class="akn-num">[A4]</span>
        <span class="akn-content">
          <span class="akn-p">Fuel oil service systems for low-flashpoint fuels for combustion purposes for propulsion or operation on board the ship are exempted from the requirements of paragraphs [A2] and [A3] above.</span>
        </span>
      </section>
      <section class="akn-paragraph">
        <span class="akn-num">[A5]</span>
        <span class="akn-content">
          <span class="akn-p">The competent authority of a Party may utilize the sampling point(s) which is fitted or designated for the purpose of taking representative samples of the fuel oil being used on board in order to verify the fuel oil complies with this regulation. Taking fuel oil samples by the competent authority of the Party shall be performed as expeditiously as possible without causing the ship to be unduly delayed.</span>
        </span>
      </section>
    </section>
  </div>`
}, {
  id: 3,
  title: 'Appendix VI',
  elementId: 'att_6',
  html: `
  <div class="akn-attachment" id="att_6" data-eid="att_6">
    <div class="akn-heading"><h2>Appendix VI</h2></div>
    <div class="akn-subheading"><h2>Verification procedures for a MARPOL Annex VI fuel oil sample (regulation 18.8.2 or regulation 14.[A1])</h2></div>
    <span class="akn-doc" data-contains="originalVersion" data-name="appendix">
      <span class="akn-mainBody">
        <span class="akn-p" id="att_2__p_1" data-eid="att_2__p_1">The following relevant verification procedure shall be used to determine whether the fuel oil delivered to and used or carried for use onboard a ship is compliant with the applicable sulphur limits required by regulation 14 of Annex VI.</span>
      </span>
    </span>
  </div>`
}, {
  id: 4,
  title: 'R1 - Application',
  elementId: 'chp_1__part_1__p_1',
  html: `
  <span class="akn-p" id="chp_1__part_1__p_1" data-eid="chp_1__part_1__p_1">The provisions of this Annex shall apply to all ships, except where expressly provided otherwise.</span>
  `
}, {
  id: 5,
  title: 'R18 para 8.2',
  elementId: 'chp_3__part_18__dvs_nn_2__para_8-2__p_1',
  html: `
  <div id="chp_3__part_18__dvs_nn_2__para_8-2__p_1">If a Party requires the representative sample to be analysed, it shall be done in accordance with the verification procedure set forth in appendix VI to determine whether the fuel oil meets the requirements of this Annex.</div>
  `
}];

// amendments to amendments
const amendmentVariants = [{
  id: '1.1',
  amends: 1,
  title: 'Update 1',
  html: `
  <div>
    <div class="akn-paragraph" eId="chp_1__part_2__para_51">
      <span class="akn-num">51</span>
      <span class="akn-content">
        <span class="akn-p">
          <span class="akn-def">Sulphur content of fuel oil</span> means the concentration of sulphur in a fuel oil, measured in % m/m as tested in accordance with a standard acceptable to the Organization.
        </span>
      </span>
    </div>

    <div class="akn-paragraph" eId="chp_1__part_2__para_52">
      <span class="akn-num">52</span>
      <span class="akn-content">
        <span class="akn-p">
          <span class="akn-def">Low-flashpoint fuel</span> means gaseous or liquid fuel oil having a flashpoint lower than otherwise permitted under paragraph 2.1.1 of SOLAS regulation II-2/4.
        </span>
      </span>
    </div>

    <div class="akn-paragraph" eId="chp_1__part_2__para_53">
      <span class="akn-num">53</span>
      <span class="akn-content">
        <span class="akn-p">
          <span class="akn-def">MARPOL delivered sample</span> means the sample of fuel oil delivered in accordance with regulation 18.8.1 of MARPOL Annex VI.
        </span>
      </span>
    </div>

    <div class="akn-paragraph" eId="chp_1__part_2__para_54">
      <span class="akn-num">54</span>
      <span class="akn-content">
        <span class="akn-p">
          <span class="akn-def">[MARPOL] in-use sample</span> means the sample of fuel oil in use on a ship.
        </span>
      </span>
    </div>

    <div class="akn-paragraph" eId="chp_1__part_2__para_55">
      <span class="akn-num">55</span>
      <span class="akn-content">
        <span class="akn-p">
          <span class="akn-def">[MARPOL] onboard sample</span> means the sample of fuel oil intended to be used or carried for use on board that ship.
        </span>
      </span>
    </div>
  </div>
  `
}, {
  id: '2.1',
  amends: 2,
  title: 'Update 1',
  html: `
  <div>
    <section class="akn-division" id="chp_3__part_14__dvs_nn_4">
      <h2>[MARPOL] in-use fuel oil sampling and testing</h2>
      <section class="akn-paragraph" id="chp_3__part_14__dvs_nn_5__para_8">
        <span class="akn-num">8</span>
        <span class="akn-content">
          <span class="akn-p">If the competent authority of a Party requires the [MARPOL] in-use or [MARPOL] onboard fuel oil sample to be analysed, it shall be done in accordance with the verification procedure set forth in appendix VI to determine whether the fuel oil being used or carried for use on board meets the requirements in paragraph 1 or paragraph 4 of this regulation. The [MARPOL] in-use fuel oil sample shall be drawn taking into account the guidelines developed by the Organization.  The [MARPOL] onboard fuel oil sample shall be drawn taking into account the guidelines to be developed by the Organization.</p>
        </span>
      </section>
    </section>
    <section class="akn-division" id="chp_3__part_14__dvs_nn_5">
      <h2>[MARPOL] in-use fuel oil sampling point</h2>
      <section class="akn-paragraph" id="chp_3__part_14__dvs_nn_5__para_9">
        <span class="akn-num">9</span>
        <span class="akn-content">
          <span class="akn-p">For each ship subject to regulations 5 and 6 of this Annex, sampling point(s) shall be fitted or designated for the purpose of taking representative samples of the fuel oil being used on board the ship taking into account guidelines developed by the Organization.</p>
        </span>
      </section>
      <section class="akn-paragraph" id="chp_3__part_14__dvs_nn_5__para_10">
        <span class="akn-num">10</span>
        <span class="akn-content">
          <span class="akn-p">For a ship constructed before entry into force of these requirements, the sampling point(s) referred to in paragraph 9 shall be fitted or designated not later than the first renewal survey that occurs after entry into force of these regulations or 12 months after, whichever is later.</p>
        </span>
      </section>
      <section class="akn-paragraph" id="chp_3__part_14__dvs_nn_5__para_11">
        <span class="akn-num">11</span>
        <span class="akn-content">
          <span class="akn-p">The requirements of paragraphs 9 and 10 above are not applicable to a fuel oil service system for a low-flashpoint fuel for combustion purposes for propulsion or operation on board the ship.</p>
        </span>
      </section>
      <section class="akn-paragraph" id="chp_3__part_14__dvs_nn_5__para_12">
        <span class="akn-num">12</span>
        <span class="akn-content">
          <span class="akn-p">The competent authority of a Party shall, as appropriate, utilize the sampling point(s) which is fitted or designated for the purpose of taking representative sample(s) of the fuel oil being used on board in order to verify the fuel oil complies with this regulation. Taking fuel oil samples by the competent authority of the Party shall be performed as expeditiously as possible without causing the ship to be unduly delayed.</p>
        </span>
      </section>
    </section>
  </div>
  `
}, {
  id: '2.2',
  amends: 2,
  title: 'Update 2',
  html: `
  <div>
    <section class="akn-division" id="chp_3__part_14__dvs_nn_4">
      <h2>In-use fuel oil sampling and testing</h2>
      <section class="akn-paragraph" id="chp_3__part_14__dvs_nn_5__para_8">
        <span class="akn-num">8</span>
        <span class="akn-content">
          <span class="akn-p">If the competent authority of a Party requires the in-use or on board fuel oil sample to be analysed, it shall be done in accordance with the verification procedure set forth in appendix VI to determine whether the fuel oil being used or carried for use on board meets the requirements in paragraph 1 or paragraph 4 of this regulation. The in-use fuel oil sample shall be drawn taking into account the guidelines developed by the Organization.  The on board fuel oil sample shall be drawn taking into account the guidelines to be developed by the Organization.</span>
        </span>
      </section>
    </section>
    <section class="akn-division" id="chp_3__part_14__dvs_nn_5">
      <h2>In-use fuel oil sampling point</h2>
      <section class="akn-paragraph" id="chp_3__part_14__dvs_nn_5__para_9">
        <span class="akn-num">9</span>
        <span class="akn-content">
          <span class="akn-p">For each ship subject to regulations 5 and 6 of this Annex, sampling point(s) shall be fitted or designated for the purpose of taking representative samples of the fuel oil being used on board the ship taking into account guidelines developed by the Organization.</span>
        </span>
      </section>
      <section class="akn-paragraph" id="chp_3__part_14__dvs_nn_5__para_10">
        <span class="akn-num">10</span>
        <span class="akn-content">
          <span class="akn-p">For a ship constructed before entry into force of these requirements, the sampling point(s) referred to in paragraph 9 shall be fitted or designated no later than the first renewal survey that occurs 12 months or more after the entry into force of this regulation.</span>
        </span>
      </section>
      <section class="akn-paragraph" id="chp_3__part_14__dvs_nn_5__para_11">
        <span class="akn-num">11</span>
        <span class="akn-content">
          <span class="akn-p">The requirements of paragraphs 9 and 10 above are not applicable to a fuel oil service system for a low-flashpoint fuel for combustion purposes for propulsion or operation on board the ship.</span>
        </span>
      </section>
      <section class="akn-paragraph" id="chp_3__part_14__dvs_nn_5__para_12">
        <span class="akn-num">12</span>
        <span class="akn-content">
          <span class="akn-p">The competent authority of a Party shall, as appropriate, utilize the sampling point(s) which is fitted or designated for the purpose of taking representative sample(s) of the fuel oil being used on board in order to verify the fuel oil complies with this regulation. Taking fuel oil samples by the competent authority of the Party shall be performed as expeditiously as possible without causing the ship to be unduly delayed.</span>
        </span>
      </section>
    </section>
  </div>
  `
}];

export const amendments = rawAmendments.map((a) => new Amendment(a.id, a.html, a.elementId, a.afterElementId, a.title));

export const variants = new Map();
for (const v of amendmentVariants) {
  variants.set(v.id, v);
}
