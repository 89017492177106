<template>
  <div>
    <div class="extra-content pa-4">
      <section class="d-flex">
        <v-spacer></v-spacer>
        <v-icon @click="chrome.expandDrawer = false" large>mdi-close</v-icon>
      </section>
      <h6 class="text-h6 mt-3">Table of Contents</h6>
    </div>
    <v-sheet class="pt-4 px-4">
      <v-list dense>
        <v-menu open-on-hover offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-content>Version {{ expression.expression_date }}</v-list-item-content>
              <v-list-item-icon>
                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </template>

          <v-list>
            <v-list-item
              v-for="expr in expressions"
              :key="expr.expression_frbr_uri"
              :to="'/instrument' + expr.expression_frbr_uri">
              {{ expr.expression_date }}
            </v-list-item>
            <v-divider />

            <v-list-item two-line @click="openVersionDialog">
              <v-list-item-content>
                <v-list-item-title>What version do I need?</v-list-item-title>
                <v-list-item-subtitle>Find a version for a specific date.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line to="/history">
              <v-list-item-content>
                <v-list-item-title>Full history</v-list-item-title>
                <v-list-item-subtitle>All amendments to MARPOL Annex VI</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <VersionChooser ref="versionDialog" />
      </v-list>

      <v-text-field
        v-model="search"
        label="Search"
        placeholder="Search"
        flat
        solo-inverted
        hide-details
        clearable
        clear-icon="mdi-close-circle-outline"
      ></v-text-field>

      <v-row dense class="my-2" justify="center">
        <v-col>
          <v-btn text block class="text-capitalize" @click="open = []">
            Collapse
          </v-btn>
        </v-col>
        <v-col>
          <v-btn text block class="text-capitalize" @click="expandAll">
            Expand
          </v-btn>
        </v-col>
      </v-row>
    </v-sheet>

    <v-treeview
      dense
      :search="search"
      :open="open"
      :items="items"
      hoverable
      :active="active"
      item-text="title"
      :open-on-click="openOnClick"
      class="toc-tree"
      @update:active="activeUpdated"
    >
      <template slot="label" slot-scope="{ item, open }">
        <div @click="clicked(item.id, open)">
          {{ item.title }}
        </div>
      </template>
    </v-treeview>
  </div>
</template>

<script>
import { scrollToId } from '../utils';
import VersionChooser from './VersionChooser';
import { Marpol } from '../data/marpol';
import { chrome, matchWindowSize } from '../data/chrome';
import './TOCMenu.scss';

export default {
  name: 'TOCMenu',
  props: ['expression', 'active'],
  components: {
    VersionChooser
  },
  data: function () {
    return {
      items: this.expression.toc.items,
      open: [],
      search: null,
      versionDialog: false,
      expressions: [...Marpol.expressions].reverse(),
      openOnClick: true,
      chrome
    };
  },
  methods: {
    clicked (id, open) {
      this.openOnClick = !open;
      matchWindowSize();
      scrollToId(id);
    },
    activeUpdated (active) {
      if (active.length) {
        window.location.hash = '#' + active[0];
      }
    },
    recursiveExpand (arr) {
      let result = [];

      arr.forEach(el => {
        if (el.children && el.children.length > 0) {
          result.push(el.id);
          result = result.concat(this.recursiveExpand(el.children));
        };
      });

      return result;
    },
    expandAll () {
      this.open = this.recursiveExpand(this.items);
    },
    openVersionDialog () {
      this.$refs.versionDialog.open();
    }
  },
  watch: {
    search (newValue, oldValue) {
      if (newValue !== null) {
        this.expandAll();
      } else this.open = [];
    }
  },
  mounted () {
    let activeId = window.location.hash;
    activeId = activeId.slice(1);
    if (activeId) {
      setTimeout(function () {
        scrollToId(activeId);
      }, 5000);
    }
  }
};
</script>

<style scoped>
@media screen and (min-width: 960px) {
  .extra-content {
    display: none;
  }
}
</style>
