<template>
  <v-container>
    <h2 class="mb-4">History of MARPOL Annex VI</h2>

    <v-timeline dense align-top>
      <HistoryItem
        v-for="(history, index) in items"
        :key="index"
        :item="history"
      />
    </v-timeline>
  </v-container>
</template>

<script>
import { Marpol } from '../data/marpol';
import HistoryItem from '../components/HistoryItem';

export default {
  name: 'History',
  components: { HistoryItem },
  data: () => ({
    items: Marpol.history
  })
};
</script>
